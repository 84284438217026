<template>
  <div class="col mt-4 pt-2" id="tables">
    <div class="component-wrapper rounded shadow">
      <div class="p-4 border-bottom">
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <h4 class="title mb-0 col" style="display: inline-block;">
              {{ $t('albu.list') }}
            </h4>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.lazy.trim="query"
              append-icon="mdi-magnify"
              :label="$t('shops.search')"
              single-line
              hide-details
              clearable
              outlined
              dense
              rounded
              @input="searcheChange"
            >
            </v-text-field
          ></v-col>
        </v-row>
      </div>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="700px"
        class="col-auto mt-10"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-5 mt-5"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('albu.new') }}
          </v-btn>
        </template>

        <v-card :loading="myloading" :disabled="myloading" class="mt-15">
          <v-card-title>
            <span class="text-h5">{{ $t('albu.newal') }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultBoutique"
                    :items="boutiquesByUsersCon"
                    :label="$t('category.select')"
                    item-text="nom"
                    item-value="nom"
                    return-object
                    @change="isVisible()"
                  >
                  </v-select>
                </v-col>
                <v-col v-if="visible" cols="12" sm="6">
                  <v-select
                    v-model="grandCategorie"
                    :items="cat"
                    :label="$t('category.grand')"
                    item-text="libelle"
                    item-value="value"
                    return-object
                    @change="catBygrandCat()"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultCategorie"
                    :items="categoriesBoutiques"
                    :label="$t('albu.select')"
                    item-text="libelle"
                    item-value="value"
                    return-object
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="defaultAlbum.designation"
                    :label="$t('albu.title')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="defaultAlbum.prix"
                    type="number"
                    :label="$t('albu.prix')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultAlbum.devise"
                    :items="devies"
                    item-text="libelle"
                    item-value="libelle"
                    :label="$t('currency.devise')"
                    return-object
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultAlbum.enstock"
                    :items="tab"
                    :label="$t('item')"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultPromotion"
                    :items="promotions"
                    item-text="libelle"
                    item-value="libelle"
                    :label="$t('albu.promotion')"
                    return-object
                  >
                  </v-select>
                </v-col>

                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-text-field
                    v-model="defaultAlbum.pourcentage"
                    counter="3"
                    type="number"
                    :label="$t('albu.pourcentage')"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="defaultAlbum.photo_entete"
                    accept="image/png, image/jpg, image/jpeg, image/bmp, image/webp"
                    prepend-icon="mdi-camera"
                    :label="$t('albu.header')"
                    required
                  >
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="defaultAlbum.fichier"
                    accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                    :label="$t('albu.photo')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-file-input
                    v-model="defaultAlbum.video"
                    accept="video/mp4,video/avi,video/webm,video/mkv,video/flv"
                    :label="$t('albu.video')"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small abel color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaultAlbum.description"
                    :label="$t('albu.description')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-menu
                    v-model="defaultAlbum.dateDebutPromo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y 
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                      :label="$t('albu.dateDebut')"
                        prepend-icon="event"
                        readonly
                        :value="fromDateDisp"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="fromDateVal"
                      no-title
                      @input="fromDateMenu = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                
                </v-col>
                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-menu
                    v-model="defaultAlbum.dateFinPromo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y 
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :label="$t('albu.dateFin')"
                        prepend-icon="event"
                        readonly
                        :value="fromDateDispFin"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="fromDateValFin"
                      no-title
                      @input="fromDateMenuFin = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>

            <small>*{{ $t('required') }}</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="close">
              {{ $t('cancel') }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="save">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog1" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t('delete1') }} ?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="close1">
              {{ $t('cancel') }}
            </v-btn>

            <v-btn color="blue darken-1" text @click="deleteAlbum">
              OK
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" persistent max-width="600px" class="col-auto">
        <v-card :loading="myloading" :disabled="myloading">
          <v-card-title>
            <span class="text-h5">{{ $t('update') }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="defaultAlbum.photo_entete"
                    accept="image/png, image/jpg, image/jpeg, image/bmp, image/webp"
                    prepend-icon="mdi-camera"
                    :label="$t('albu.header')"
                    required
                  >
                  </v-file-input>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="defaultAlbum.designation"
                    :label="$t('albu.title')"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="defaultAlbum.prix"
                    type="number"
                    :label="$t('albu.prix')"
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="defaultAlbum.enstock"
                    :label="$t('item')"
                    :items="tab"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultAlbum.devise"
                    :items="devies"
                    item-text="libelle"
                    item-value="libelle"
                    :label="$t('currency.devise')"
                    return-object
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="defaultPromotion"
                    :items="promotions"
                    item-text="libelle"
                    item-value="libelle"
                    :label="$t('albu.promotion')"
                    return-object
                  >
                  </v-select>
                </v-col>
                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-text-field
                    v-model="defaultAlbum.pourcentage"
                    counter="3"
                    type="number"
                    :label="$t('albu.pourcentage')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaultAlbum.description"
                    :label="$t('albu.description')"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-menu
                    v-model="defaultAlbum.dateDebutPromo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y 
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                      :label="$t('albu.dateDebut')"
                        prepend-icon="event"
                        readonly
                        :value="fromDateDisp"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="fromDateVal"
                      no-title
                      @input="fromDateMenu = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                
                </v-col>
                <v-col v-if="isPromotion" cols="12" sm="6">
                  <v-menu
                    v-model="defaultAlbum.dateFinPromo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y 
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :label="$t('albu.dateFin')"
                        prepend-icon="event"
                        readonly
                        :value="fromDateDispFin"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="fromDateValFin"
                      no-title
                      @input="fromDateMenuFin = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close2">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="update">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="row p-4">
        <div
          class="col-lg-3 col-md-4 mt-4 pt-2"
          v-for="alb in albumsPage.data"
          :key="alb.albums.idArticle"
        >
          <div class="card categories overflow-hidden rounded shadow border-0">
            <img
              :src="alb.albums.photo_entete"
              class=""
              alt="fichier introuvable"
              height="170px"
            />
            <div class="card-body" style="padding: 0;">
              <p
                class="title p-titre h7 text-dark gallery"
                style="font-size: 15px !important"
              >
                {{ alb.albums.designation }}
              </p>
            </div>
            <div class="card-body" style="padding: 0;">
              <ul class="list-unstyled d-flex justify-content-between mb-0">
                <li>
                  <a
                    class="title h7 text-dark gallery"
                    style="font-size: 15px !important"
                    >{{ alb.albums.titre }}</a
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      path: `/${$i18n.locale}/dashboard/detailsAlbum/${alb.albums.idArticle}`,
                    }"
                    ><i class="mdi mdi-24px mdi-image-multiple icon"></i
                  ></router-link>
                </li>
                <li>
                  <v-btn @click="openDialog(alb)" color="red" dark icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </li>
                <li>
                  <v-btn @click="openDialog1(alb)" color="green" dark icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-5">
          <b-pagination
            class="align-self-center"
            @change="handlePageChange"
            :total-rows="totalRow"
            :per-page="perPage"
            v-model="page"
          ></b-pagination>
        </div>
      </div>
      <!--end row-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import de from '/public/js/app.js';
import da from '/public/js/feather.min.js';
import te from '/public/js/plugins.init.js';
import tr from '/public/js/tobii.min.js';
import tt from '/public/js/bootstrap.bundle.min.js';
import td from '/public/js/shuffle.min.js';
import { compressImage } from '../../helper';
export default {
  data: () => {
    return {
      albu: {},
      myloading: false,
      dialog1: false,
      dialog2: false,
      alert1: false,
      alert2: false,
      alert: false,
      files: [],
      dialog: false,
      visible: false,
      categories: [1, 2, 3],
      grandCategorie: null,
      query: '',
      page: 1,
      fromDateMenu: false,
      fromDateVal: '',
      fromDateMenuFin: false,
      fromDateValFin: '',
      minDate: "2020-01-05",
      maxDate: "2019-08-30",
      cat: [
        {
          id: 1,
          libelle: 'S2A EQUIPEMENT',
          value: 'S2A',
        },
        {
          id: 2,
          libelle: 'OUTILS SPORTIF',
          value: 'OUTILS',
        },
        {
          id: 3,
          libelle: 'AUTRES MARQUES',
          value: 'AUTRES',
        },
      ],
      promotions: [
        {
          id: 1,
          libelle: 'Non',
          value: false,
        },
        {
          id: 2,
          libelle: 'Oui',
          value: true,
        },
      ],
      defaultBoutique: {
        id: 0,
        nom: '',
        adresse: '',
        telephone: '',
      },
      initialBoutique: {
        id: 0,
        nom: '',
        adresse: '',
        telephone: '',
      },
      defaultCategorie: {
        id: 0,
        libelle: '',
      },
      defaultPromotion: {
        id: 0,
        libelle: '',
        value: false,
      },

      initialCategorie: {
        id: 0,
        libelle: '',
      },

      defaultAlbum: {
        idBoutique: 0,
        idCategorie: 0,
        devise: null,
        photo_entete: null,
        designation: '',
        description: '',
        prix: 0,
        enstock: '',
        pourcentage: '',
        video: [],
        fichier: [],
        dateDebutPromo: '',
        dateFinPromo: ''
      },

      initialAlbum: {
        idBoutique: 0,
        idCategorie: 0,
        devise: null,
        photo_entete: null,
        designation: '',
        description: '',
        prix: 0,
        enstock: '',
        pourcentage: '',
        promotion: '',
        video: [],
        fichier: [],
        dateDebutPromo: '',
        dateFinPromo: ''
      },
    };
  },

  watch: {
    dialog1(val) {
      val || this.close1();
    },
  },

  computed: {
    ...mapGetters(['loading', 'albumsPage']),
    ...mapGetters(['loading', 'categoriesBoutiques']),
    ...mapGetters(['loading', 'boutiquesByUsersCon']),
    ...mapGetters(['loading', 'devies']),
    tab: function() {
      return [this.$t('stock'), this.$t('command')];
    },
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    fromDateDispFin() {
      return this.fromDateValFin;
      // format/do something with date
    },
    isPromotion() {
      if (this.defaultPromotion.value) {
        return this.defaultPromotion.value;
      } else {
        return false;
      }
    },

    totalRow() {
      if (this.albumsPage) {
        return this.albumsPage.total;
      } else {
        return 0;
      }
    },

    perPage() {
      if (this.albumsPage) {
        return this.albumsPage.per_page;
      } else {
        return 0;
      }
    },
  },

  created() {
    this.$store.dispatch('getAlbumPageByUsers', 1);
    this.$store.dispatch('getDevies');
    this.$store.dispatch('getBoutiquesByUsersConnect');
    this.$store.dispatch('userme');
  },

  methods: {
    handlePageChange(page) {
      if (this.query) {
        if (page) {
          this.$store.dispatch('getAlbumSearchPageByUsers', {
            page,
            search: this.query,
          });
        } else {
          this.$store.dispatch('getAlbumSearchPageByUsers', {
            page: 1,
            search: this.query,
          });
        }
      } else {
        if (page) {
          this.$store.dispatch('getAlbumPageByUsers', page);
        } else {
          this.$store.dispatch('getAlbumPageByUsers', 1);
        }
      }
    },

    openDialog(alb) {
      this.albu = alb;
      this.dialog1 = true;
    },

    openDialog1(alb) {
      this.albu = alb;
      this.dialog2 = true;
      this.defaultPromotion = this.promotions.find(
        (o) => o.value === this.albu.albums.promotion
      );
      this.defaultAlbum = {
        designation: this.albu.albums.designation,
        description: this.albu.albums.description,
        prix: this.albu.albums.prix,
        enstock: this.tab.find((e) => e === this.albu.albums.enstock),
        pourcentage: this.albu.albums.pourcentage,
        devise: this.albu.albums.devise,
      };
    },

    searcheChange() {
      if (this.query) {
        // this.$store.dispatch("getAlbumSearchByUsers", this.query);
        this.$store.dispatch('getAlbumSearchPageByUsers', {
          page: 1,
          search: this.query,
        });
      } else {
        // this.$store.dispatch("getAlbumsByUsers");
        this.$store.dispatch('getAlbumPageByUsers', 1);
      }
    },

    setGallery(image) {
      this.image = image;
    },

    isVisible() {
      const s2a = this.defaultBoutique.code;
      const s2a1 = 'S2AARF';
      if (s2a1 === s2a) {
        this.visible = true;
      } else {
        this.$store.dispatch(
          'getCategoriesBoutiques',
          this.defaultBoutique.idBoutique
        );
        this.visible = false;
      }
    },

    boutiqueChange() {
      this.$store.dispatch(
        'getCategoriesBoutiques',
        this.defaultBoutique.idBoutique
      );
    },

    catBygrandCat() {
      const s2a = this.grandCategorie.value;
      this.$store.dispatch('getCategorieGrand', s2a);
    },

    close1() {
      this.myloading = false;
      this.dialog1 = false;
      this.albu = {};
      this.grandCategorie = {};
      this.$store.dispatch('getCategoriesByBoutiques');
      this.visible = false;
      this.grandCategorie = {};
      this.$nextTick(() => {
        this.defaultPromotion = {};
        this.defaultCategorie = Object.assign({}, this.initialCategorie);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.defaultAlbum = Object.assign({}, this.initialAlbum);
      });
    },

    close2() {
      this.myloading = false;
      this.dialog2 = false;
      this.albu = {};
      this.grandCategorie = {};
      this.$store.dispatch('getCategoriesByBoutiques');
      this.visible = false;
      this.$nextTick(() => {
        this.defaultPromotion = {};
        this.defaultCategorie = {};
        this.defaultBoutique = {};
        this.defaultAlbum = {};
      });
    },

    close() {
      this.myloading = false;
      this.dialog = false;
      this.grandCategorie = {};
      this.$store.dispatch('getCategoriesByBoutiques');
      this.visible = false;
      this.$nextTick(() => {
        this.defaultPromotion = {};
        this.defaultCategorie = Object.assign({}, this.initialCategorie);
        this.defaultBoutique = Object.assign({}, this.initialBoutique);
        this.defaultAlbum = Object.assign({}, this.initialAlbum);
      });
    },

    async deleteAlbum() {
      this.myloading = true;
      try {
        await this.$store.dispatch(
          'deleteOneAlbum',
          this.albu.albums.idArticle
        );
        this.alert1 = true;
        this.close1();
      } catch (e) {}
    },

    async tr(file) {
      let compressed = await compressImage(file);
      return compressed;
    },

    async save() {
      

      this.myloading = true;
      var id = this.defaultBoutique.nom;
      var idC = this.defaultCategorie.libelle;
      var titre = this.defaultAlbum.designation;
      var prix = this.defaultAlbum.prix;
      var description = this.defaultAlbum.description;
      var fichier = this.defaultAlbum.fichier;
      var video = this.defaultAlbum.video;
      var tof = this.defaultAlbum.photo_entete;
      let lang = this.$i18n.locale;
      // console.log(id);
      // console.log(idC);
      // console.log(titre);
      // console.log(prix);
      // console.log(tof);
      // console.log(description);
      if (
        id != '' &&
        idC != '' &&
        titre != '' &&
        prix != '' &&
        description != '' &&
        tof != null &&
        this.defaultAlbum.enstock != null
      ) {

      //   console.log(this.defaultAlbum);
      // console.log(this.fromDateVal);
      // console.log(this.fromDateValFin);

        const imageCompressed = await compressImage(tof);
        var compressedFichier = [];
        var totalFileSize = 0;
        var totalVideoSize = 0;

        if (fichier.length != 0) {
          compressedFichier = await this.myfunc(fichier);
          totalFileSize = await this.mysum(compressedFichier);
        }
        if (video.length != 0) {
          video.forEach(async (entry) => {
            console.log(entry.size);
            totalVideoSize = totalVideoSize + entry.size;
          });
        }

        let itemToSave = {
          boutique: this.defaultBoutique.idBoutique,
          categorie: this.defaultCategorie.idCategorie,
          titre: this.defaultAlbum.designation,
          prix: this.defaultAlbum.prix,
          description: this.defaultAlbum.description,
          images: compressedFichier,
          enstock: this.defaultAlbum.enstock,
          pourcentage: this.defaultAlbum.pourcentage,
          promotion: this.defaultPromotion.value,
          videos: video,
          photo_entete: imageCompressed,
          devise: this.defaultAlbum.devise.id,
          dateDebutPromo : this.fromDateVal,
          dateFinPromo : this.fromDateValFin
        };
        var max = this.$store.state.auth.file_max;
        var saved = this.$store.state.auth.file_size;
        var left = max - saved;
        var total = totalVideoSize + totalFileSize;
        if (total <= left) {
          try {
            await this.$store.dispatch('postAlbum', itemToSave);
            await this.$store.dispatch('userme');
            this.$toast.success(this.$t('toast.save'));
            this.close();
          } catch (e) {
            this.$toast.error(this.$t('toast.error'));
            this.myloading = false;
          }
        } else {
          if (lang == 'fr')
            this.$toast.error(
              "Vous avez atteint votre limite d'images.Veuillez vous rendre dans l'onglet Message pour sousrire à une offre "
            );

          if (lang == 'en')
            this.$toast.error(
              'You have reached your limit of images, please go to the Message tab to subscribe to an offer'
            );
        }
      }
      this.myloading = false;
    },

    async myfunc(tab) {
      var myTab = [];
      for (var t in tab) {
        var cmp = await compressImage(tab[t]);
        myTab.push(cmp);
      }
      return myTab;
    },

    async mysum(tab) {
      var sum = 0;
      for (var t in tab) {
        sum = sum + tab[t].size;
      }
      return sum;
    },

    async update() {
      this.myloading = true;
      var titre = this.defaultAlbum.designation;
      var prix = this.defaultAlbum.prix;
      var description = this.defaultAlbum.description;
      var tof = this.defaultAlbum.photo_entete;
      let itemToSave;
      let imageCompressed;
      console.log(this.defaultAlbum);
      if (
        titre != '' ||
        prix != '' ||
        description != '' ||
        tof != null ||
        this.defaultAlbum.enstock != ''
      ) {
        if (tof != null) {
          imageCompressed = await compressImage(tof);
        } else {
          imageCompressed = null;
        }

        if (this.defaultAlbum.titre != '') {
          itemToSave = {
            id: this.albu.albums.idArticle,
            boutique: this.albu.albums.boutique.idBoutique,
            categorie: this.albu.albums.categorie.idCategorie,
            titre: this.defaultAlbum.designation,
            prix: this.defaultAlbum.prix,
            description: this.defaultAlbum.description,
            enstock: this.defaultAlbum.enstock,
            pourcentage: this.defaultAlbum.pourcentage,
            promotion: this.defaultPromotion.value,
            photo_entete: imageCompressed,
            devise: this.defaultAlbum.devise.id,
          dateDebutPromo : this.fromDateVal,
          dateFinPromo : this.fromDateValFin
          };
        } else {
          itemToSave = {
            id: this.albu.albums.idArticle,
            boutique: this.albu.albums.boutique.idBoutique,
            categorie: this.albu.albums.categorie.idCategorie,
            titre: this.defaultAlbum.designation,
            prix: this.defaultAlbum.prix,
            description: this.defaultAlbum.description,
            enstock: this.defaultAlbum.enstock,
            pourcentage: this.defaultAlbum.pourcentage,
            promotion: this.defaultPromotion.value,
            photo_entete: imageCompressed,
            devise: this.defaultAlbum.devise.id,
            dateDebutPromo : this.fromDateVal,
            dateFinPromo : this.fromDateValFin
          };
        }

        var max = this.$store.state.auth.file_max;
        var saved = this.$store.state.auth.file_size;
        var left = max - saved;
        //var total = fichier.length + 1

        try {
          await this.$store.dispatch('updateAlbum', itemToSave);
          await this.$store.dispatch('userme');
          this.$toast.success(this.$t('toast.update'));
          // this.alert2 = true;
          this.close2();
        } catch (e) {
          this.$toast.error(this.$t('toast.error'));
        }
        this.myloading = false;
      }
    },
  },
};
</script>
<style scoped>
.p-titre {
  width: 205px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
</style>
