import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import profile from './modules/profile';
import albums from './modules/albums';
import categories from './modules/categories';
import boutiques from './modules/boutiques';
import home from './modules/home';
import pays from './modules/pays';
import typeBoutique from './modules/typeBoutique';
import publicites from './modules/publicites';
import evenements from './modules/evenements';
import abonnements from './modules/abonnements';
import video from './modules/video';
import commande from './modules/commande';
import devise from './modules/devise';

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    profile,
    categories,
    boutiques,
    home,
    albums,
    pays,
    typeBoutique,
    publicites,
    evenements,
    abonnements,
    video,
    commande,
    devise,
  },
  plugins: [createPersistedState()]
});

/*
store
    -home
      ---albumsByCategories       => /Acceuil
      ---categories               => /Acceuil, /albums, /categorie/:id
      ---albums                   => /albums
      ---albumsByCategorie        => /categorie/:id
      ---categorie                => /categorie/:id
      ---album                    => /album/:id
    
    -Dashboard
      ---nom                      => ./
      ---prenom                   => ./
      ---username                 => ./
      ---token                    => /*, /logout

      ---boutiques                => /boutiques
      ---categoriesByUsers        => /categories
      ---albumsByUsers            => /albums
*/