import axios from 'axios';

const state = {
	mesCommandes: [],
	commandeFour: [],
	commandeAccepter:[],
	commandeLivrer:[],
	commandeAdmin: [],
	commandeAdminAccepter:[],
	commandeAdminLivrer:[],
	allCommandes: [],
	commande: null,
	loading: true
};

const getters = {
	mesCommandes: (state) => state.mesCommandes,
	commandeFour: (state) => state.commandeFour,
	commandeAccepter: (state) => state.commandeAccepter,
	commandeLivrer: (state) => state.commandeLivrer,
	commandeAdmin: (state) => state.commandeAdmin,
	commandeAdminAccepter: (state) => state.commandeAdminAccepter,
	commandeAdminLivrer: (state) => state.commandeAdminLivrer,
	allCommandes: (state) => state.allCommandes,
	commande: (state) => state.commande,
};

const actions = {
	async getMesCommandes({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes_client',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		console.log(data);
		commit('setMesCommandes', { mesCommandes: data })
	},
	async getCommandeFour({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes_fournisseur',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeFour', { commandeFour: data })
	},
	async getCommandeAccepter({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes/fournisseur/accepter',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeAccepter', { commandeAccepter: data })
	},
	async getCommandeLivrer({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes/fournisseur/livre',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeLivrer', { commandeLivrer: data })
	},
	async getCommandeAdmin({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes/admin',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeAdmin', { commandeAdmin: data })
	},
	async getCommandeAdminAccepter({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes/admin/accepter',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeAdminAccepter', { commandeAdminAccepter: data })
	},
	async getCommandeAdminLivrer({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes/admin/livre',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setCommandeAdminLivrer', { commandeAdminLivrer: data })
	},
	async getAllCommandes({ commit, rootState }) {
		commit('loading');
		let token = rootState.auth.token;
		let response = await axios.get('commandes_not_deleted',
			{ headers: { 'Authorization': 'Bearer ' + token } });
		let data = response.data;
		commit('setAllCommandes', { allCommandes: data })
	},
	async saveCommande({ dispatch, rootState }, command) {
		let token = rootState.auth.token;

		await axios.post('commande/save', command,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});
	},

	async savePaiement({ dispatch, rootState }, paiement) {
		let token = rootState.auth.token;
		console.log(paiement);
		// http://localhost:9193/web/service/auth/operation
		// https://shopymanager.com:8443/s2apay_dev/web/service/auth/operation
		let response = await axios.post('https://shopymanager.com:8443/s2apay/web/service/auth/operation', paiement.pai,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});
		let data = response.data;
		// console.log(data);

		if (data) {
			let comm = paiement.comm
			comm.idOperation = data.id
			await axios.post('commande/save', comm,
				{
					headers: { 'Authorization': 'Bearer ' + token }
				});
		}

	},

	async acceptCommande({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('commande/accept/' + id,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});
		dispatch("getAllCommandes")

	},
	async enableCommande({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('commande/delivery/' + id,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});
		dispatch("getAllCommandes")

	},
	async enableFourCommande({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('commande/delivery/fournisseur/' + id,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});
		dispatch("getAllCommandes")

	},
	async disableCommande({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('commande/non/livre/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getAllCommandes")
	},


	async annulerCommande({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		await axios.get('commande/annulation/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
		dispatch("getAllCommandes")
	},

	async valideOperation({ dispatch, rootState }, id) {
		let token = rootState.auth.token;
		// http://localhost:9193/web/service/auth/operation/valide/
		// https://shopymanager.com:8443/s2apay_dev/web/service/auth/operation/valide/
		await axios.post('https://shopymanager.com:8443/s2apay/web/service/auth/operation/valide/' + id,
			{
				headers: { 'Authorization': 'Bearer ' + token }
			});

	},
	// async annuleOperation({ dispatch, rootState }, id) {
	// 	let token = rootState.auth.token;
	// 	// http://localhost:9193/web/service/auth/operation/annule
	// 	// https://shopymanager.com:8443/s2apay_dev/web/service/auth/operation/annule/
	// 	await axios.post('https://shopymanager.com:8443/s2apay/web/service/auth/operation/annule/' + id,
	// 		{
	// 			headers: { 'Authorization': 'Bearer ' + token }
	// 		});

	// },

};

const mutations = {
	loading(state) {
		state.loading = true;
	},

	setMesCommandes(state, { mesCommandes }) {
		state.mesCommandes = mesCommandes;
		state.loading = false;
	},
	setCommandeFour(state, { commandeFour }) {
		state.commandeFour = commandeFour;
		state.loading = false;
	},

	setCommandeAccepter(state, { commandeAccepter }) {
		state.commandeAccepter = commandeAccepter;
		state.loading = false;
	},
	setCommandeLivrer(state, { commandeLivrer }) {
		state.commandeLivrer = commandeLivrer;
		state.loading = false;
	},
	setCommandeAdmin(state, { commandeAdmin }) {
		state.commandeAdmin = commandeAdmin;
		state.loading = false;
	},

	setCommandeAdminAccepter(state, { commandeAdminAccepter }) {
		state.commandeAdminAccepter = commandeAdminAccepter;
		state.loading = false;
	},
	setCommandeAdminLivrer(state, { commandeAdminLivrer }) {
		state.commandeAdminLivrer = commandeAdminLivrer;
		state.loading = false;
	},
	setAllCommandes(state, { allCommandes }) {
		state.allCommandes = allCommandes;
		state.loading = false;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};